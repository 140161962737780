<template>
  <section id="card-images">
    <b-row>
      <b-col
        v-for="item in options"
        :key="item.category.name"
        md="4"
        lg="3"
        xl="2"
      >
        <router-link
          class="category__link"
          :to="'category/'+item.category.slug"
        >
          <b-card
            :img-src="'https://content.brimecdn.com/cdn-cgi/image/quality=100/'+item.category.cover.replace('images.igdb.com', 'images.igdb.com').replace('t_1080p', 't_cover_big')"
            border-variant="primary"
            class="mb-2"
          ><span
            id="streamTitle"
          ><strong>{{ item.category.name }}</strong></span>
            <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="primary"
            :href="item.strm"
          >
            Watch
          </b-button> -->
          </b-card>
        </router-link>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BCard } from 'bootstrap-vue'

import Vue from 'vue'

Vue.filter('capitalize', string => {
  const capitalFirst = string.toUpperCase()
  return capitalFirst
})
const prefix = 'https://content.brimecdn.com/live/'
const suffix = '/thumbnail.jpg'
export default {
  components: {
    BRow,
    BCol,
    BCard,
  },
  data() {
    return {
      options: {},
      test: {},
      prefix,
      suffix,
    }
  },
  mounted() {
    document.title = 'Live Categories - Brime'
  },
  async beforeCreate() {
    // this.$http.get('').then(res => { this.options = res.data.streams })
    this.options = await window.brime.getAllLiveCategories()
  },
}
</script>
<style scoped>
/* Scrollbar styles */
body {
  overflow: hidden; /* Hide scrollbars */
}
#style-4::-webkit-scrollbar-track
{
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
background-color: #F5F5F5;
}

#style-4::-webkit-scrollbar
{
width: 10px;
background-color: #F5F5F5;
}

#style-4::-webkit-scrollbar-thumb
{
background-color: #000000;
border: 2px solid #555555;
}

.card-body {
  padding: 0.5rem;
}

.card {
  box-shadow: 0px 0px 0px rgba(0,0,0,0);
  transition: all 0.2s;
}

.card:hover {
  transform: translateY(-8px);
  box-shadow: 0px 0px 20px rgba(115,103,240,1);
}

.card img {
  transition: filter 0.2s;
  filter: saturate(0.65);
}

.card:hover img {
  filter: saturate(1)
}

.category__link {
  display: block;
  height: 100%;
  padding-bottom: 2.5rem;
}

.category__link .card {
  height: 100%;
}

.category__link img {
  flex: 1;
}

.category__link #streamTitle {
  margin: 0;
}

.category__link .card-body {
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0.5rem;
}

#category {
  color: #b4b7bd;
}
#streamtitle {
  font-size: 14px;
  text-overflow: ellipsis;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
#stream {
  display: block;
  text-align: center;
  margin-top: -15px;
  margin-bottom: 10px;
}
#streamTitle {
  color: #fff;
  font-size: 14px;
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: -10px;
}
</style>
